import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../../config/axios/interceptor";
import {
  getHospitalInfoPrint,
  getHospitalLogoPrint,
} from "../../../api/settings/Settings";
import "react-quill/dist/quill.snow.css"; // Import Quill's CSS for styling
import ReactQuill from "react-quill";
import OTPInput from "../../auth/signup/otp/otp-field";
import Button from "../../common/buttons/Button";
import { showErrorToast, showSuccessToast } from "../../../utils/toaster";
import ModalWrapper from "../../common/modal/ModalWrapper";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

// Extend Day.js with plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const ConsentForm = () => {
  // Get the current location
  const location = useLocation();
  // Parse the query parameters
  const queryParams = new URLSearchParams(location.search);
  const patientId = queryParams.get("patient_id");
  const hospitalId = queryParams.get("hospitalId");
  const [consentData, setConsendData] = useState({});
  const [details, setDetails] = useState("");
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();
  const [logoImage, setLogoImage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);

  const [otp, setOtp] = useState("");

  const getConsentForm = async () => {
    try {
      const response = await api.get(`/patient/view2/${patientId}`);
      setUserData(response.data.data);
    } catch {
    } finally {
    }
  };

  const getLogoPic = () => {
    getHospitalLogoPrint(hospitalId)
      .then((response) => {
        setLogoImage(response);
      })
      .catch((error) => {
        console.log({ error });
      })
      .finally(() => {});
  };

  const getPrefix = () => {
    getHospitalInfoPrint(hospitalId)
      .then((response) => {
        setConsendData(response.data.data);
        setDetails(response.data?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  const handleOnOtpChange = (value) => {
    const otpValue = value.join("");
    setOtp(otpValue);
    if (otpValue.length === 4) {
      // Validate the OTP value and submit the form
      setTimeout(() => {}, 0);
    }
  };
  const handleSubmitOtp = async () => {
    if (!otp.length === 4) {
      showErrorToast("OTP must be exactly 4 digits");
      return;
    }
    const payload = {
      patient_id: patientId,
      otp: otp,
    };
    try {
      const response = await api.post("/patient/verifyConsent/view2", payload);
      showSuccessToast("Success");
      setShowSuccess(true);
    } catch (error) {
      showErrorToast(error);
    } finally {
    }
  };

  const handleCloseWindow = () => {
    window.close();
    window.location.href = "https://www.google.com";
  };

  useEffect(() => {
    if ((patientId, hospitalId)) {
      getPrefix();
      getLogoPic();
      getConsentForm();
    }
  }, []);
  console.log({ userData });

  return (
    <div className={"p-10 flex flex-col gap-5 "}>
      <div className="border-b border-black pb-5">
        <div className=" w-full grid justify-center">
          {/* <!-- Image head --> */}
          <div className="flex ">
            <img src={logoImage} className="max-h-[100px] object-fill w-full" />
          </div>
          <div className="mt-2 flex gap-1 flex-wrap w-full">
            <p>{details.addressLine1} </p>
            <p>{details.addressLine2} </p>
            <p>{details.addressLine3} </p>
            <p>{details.addressLine4} </p>
          </div>
          <div className="mt-2 flex gap-1 flex-wrap">
            <div className="flex items-center">
              <p className="w-fit text-sm">Contact No - </p>
              <p> {details?.phoneNumber1} </p>
            </div>
          </div>
        </div>
      </div>
      <div className="border-b border-black pb-5">
        <div className=" items-center mt-5">
          <div className="space-y-2">
            <div className="flex">
              <span className="w-[200px]">Patient ID</span>
              <span>{userData?.patient_id} </span>
            </div>
            <div className="flex">
              <span className="w-[200px]">Name</span>
              <span className="min-w-max">{userData?.name} </span>
            </div>
            <div className="flex">
              <span className="w-[200px]">Gender</span>
              <span className="min-w-max">{userData?.gender}</span>
            </div>

            <div className="flex">
              <span className="w-[200px]">Age </span>
              <span className="min-w-max">{userData?.age}</span>
            </div>
          </div>
        </div>
      </div>
      <style>
        {`
          .ql-container {
            border: none !important;  /* Force remove border */
            padding: 0 !important;    /* Force remove padding */
            margin: 0 !important;     /* Force remove margin */
          }
          .ql-editor {
            border: none !important;  /* Force remove border */
            padding: 0 !important;    /* Force remove padding */
            margin: 0 !important;     /* Force remove margin */
          }
        `}
      </style>
      <ReactQuill
        value={consentData.consent_text}
        readOnly={true} // Disable editing
        theme="snow" // Use the snow theme for consistent styling
        modules={{ toolbar: false }} // Hide the toolbar since this is read-only
        className=""
        style={{
          border: "none", // Removes the border
          padding: "0", // Removes the padding
          margin: "0", // Removes the margin
        }}
      />
      {!userData.consent_status && (
        <div className="space-y-2">
          <div className="space-y-2">
            <h3 className="text-sm">Enter the OTP</h3>
            <OTPInput
              onOtpChange={handleOnOtpChange}
              className={"border-black"}
            />
          </div>
          <div className="flex justify-center">
            <Button
              onClick={handleSubmitOtp}
              type="primary"
              className={"!w-[200px]"}
            >
              Submit
            </Button>
          </div>
        </div>
      )}
      {userData?.consent_status && (
        <div className="md:w-[500px]">
          <div className="shadow-card rounded-15 flex  justify-center">
            <div className=" flex md:flex-row flex-col  p-4 w-full justify-center">
              <div className="flex flex-col min-w-[200px]">
                <p className="text-darkgrey  text-bodyBB mb-2">
                  Consent Status
                </p>
                <div class=" grid w-full  items-center">
                  <div class="text-bodyRB text-darkgrey w-full">
                    {userData?.consent_status ? "Approved" : "Pending"}
                  </div>
                </div>
              </div>
              {userData?.consent_approved_time && (
                <div className="">
                  <p className="text-darkgrey  text-bodyBB mb-2 capitalize">
                    consent approved on
                  </p>
                  <div className="">
                    <div className="flex gap-3">
                      <span className="text-sm">
                        {dayjs
                          .utc(userData.consent_approved_time)
                          .tz(dayjs.tz.guess())
                          .format("DD-MM-YYYY, hh:mm A")}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <ModalWrapper open={showSuccess} title={""} showClose={false}>
        <div className="w-[400px] h-[150px] gap-3 grid justify-center text-center">
          <div className="flex justify-center">
            <img
              src="../success-svgrepo-com.svg"
              className="w-20 h-fit "
              alt=""
            />
          </div>
          <h2 className="text-[16px] font-semibold text-center">
            Thank you for submitting the treatment consent form.
          </h2>
          <p className="font-medium text-sm">Please close this window</p>
        </div>
        <div>
          <div className="flex justify-center mt-5">
            <Button
              onClick={handleCloseWindow}
              type="primary"
              action={"button"}
              className={"!w-[100px] !h-[40px]"}
            >
              Close
            </Button>
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

export default ConsentForm;
