import dayjs from "dayjs";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export // Function to format the date range display
const formatDateOneWeekRange = ({ startDate, endDate }) => {
  if (startDate && endDate) {
    const startFormatted = `${startDate.getDate()} ${startDate.toLocaleString(
      "default",
      { month: "short" }
    )} `;
    const endFormatted = `${endDate.getDate()} ${endDate.toLocaleString(
      "default",
      { month: "short" }
    )} ${endDate.getFullYear()}`;
    return `${startFormatted} - ${endFormatted}`;
  } else if (startDate) {
    return `${startDate.getDate()} ${startDate.toLocaleString("default", {
      month: "long",
    })} ${startDate.getFullYear()}`;
  } else {
    return "Select a date range";
  }
};

export const calculateAge = (dateOfBirth) => {
  return new Promise((resolve, reject) => {
    try {
      if (!dateOfBirth) {
        resolve("");
        return;
      }

      const dob = dayjs(dateOfBirth);
      const currentDate = dayjs();

      if (!dob.isValid()) {
        reject(new Error("Invalid date format"));
        return;
      }

      // Ensure the date of birth is not in the future
      if (dob.isAfter(currentDate)) {
        console.log("Date of birth cannot be in the future");
        return;
      }

      let yearsDiff = currentDate.year() - dob.year();
      const hasBirthdayOccurred =
        currentDate.month() > dob.month() ||
        (currentDate.month() === dob.month() &&
          currentDate.date() >= dob.date());

      if (!hasBirthdayOccurred) {
        yearsDiff--;
      }

      resolve(yearsDiff);
    } catch (error) {
      console.log(error);
    }
  });
};

export const formatNormalDate = (date) => {
  if (date) {
    try {
      return dayjs(date).format("YYYY-MM-DD");
    } catch {
      console.log("Date format Error");
    }
  } else {
    return "-";
  }
};
export const formatNormalTime = (date) => {
  if (date) {
    try {
      const isoDateString = date;
      const isoDate = new Date(isoDateString);

      // Extract the date components
      const day = isoDate.getUTCDate();
      const month = isoDate.getUTCMonth() + 1; // Adding 1 because months are 0-indexed
      const year = isoDate.getUTCFullYear();

      // Extract the time components
      const hours = isoDate.getUTCHours();
      const minutes = isoDate.getUTCMinutes();
      const seconds = isoDate.getUTCSeconds();

      // Create the formatted date and time string
      const formattedDateTime = `${day < 10 ? "0" : ""}${
        month < 10 ? "0" : ""
      }${hours}:${minutes} `;

      return formattedDateTime;
    } catch {
      console.log("Date format Error");
    }
  } else {
    return "-";
  }
};

export const formatDateToString = (date) => {
  try {
    return dayjs.utc(date).format("YYYY-MM-DD");
  } catch (error) {
    console.log("formatDateToString Error");
  }
};

export const formatToSearchDate = (date) => {
  if (date) {
    try {
      return dayjs(date).format("YYYY-MM-DD");
    } catch {
      console.log("Date format Error");
    }
  } else {
    return "";
  }
};

export const displayDate = (date) => {
  if (date) {
    try {
      const formattedDate = dayjs.utc(date).format("DD-MM-YYYY");
      return formattedDate !== "Invalid Date" ? formattedDate : "-";
    } catch {
      console.log("Date format Error");
      return "-";
    }
  } else {
    return "";
  }
};

export const formatDate = (date) => {
  if (date) {
    try {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      return formattedDate !== "Invalid Date" ? formattedDate : "-";
    } catch {
      console.log("Date format Error");
    }
  } else {
    return "";
  }
};

export const handle24Time = (time) => {
  try {
    // Parse the railway time using Day.js
    const parsedDate = dayjs(`2023-01-01 ${time}`, {
      format: "YYYY-MM-DD HH:mm",
    });

    // Format the time with AM/PM
    const formattedTime = parsedDate.format("hh:mm A");
    return formattedTime;
  } catch {
    return "Time Format Error";
    // console.log("Time Format Error");
  }
};
