import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loggedIn: false,
  token: null,
  userId: null,
  displayName: null,
  companyId: null,
  role: null,
  isOpen: false,
  subscriptionInfo: "",
  treatment_qty: false,
  whatsapp_credit_balance: "0",
  sms_credit_balance: "0",
  variant: "null",
  subscription_started_at: "-",
  subscription_expires_at: "-",
  plan_name: "Free Trial",
  birthdayWish: false,
  labOrderArrivalUpdate: false,
  financeAbstract: false,
};

export const authSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    userSettings: (state, action) => {
      state.loggedIn = true;
      state.token = action.payload?.token;
      localStorage.setItem("token", action.payload.token);
      state.userId = action.payload?._id;
      state.displayName = action.payload?.name;
      state.companyId = action.payload?.company_id;
      state.role = action.payload?.role1;
      state.email = action.payload?.emailId;
      state.phone = action.payload?.phoneNumber;
      state.subscriptionInfo = action.payload?.subscriptionInfo;
      state.treatment_qty = action.payload?.treatment_qty;
      state.whatsapp_credit_balance = action.payload?.whatsapp_credit_balance;
      state.sms_credit_balance = action.payload?.sms_credit_balance;
      state.variant = action.payload?.variant;
      state.subscription_started_at = action.payload?.subscription_started_at;
      state.subscription_expires_at = action.payload?.subscription_expires_at;
      state.plan_name = action.payload?.plan_name;
      state.birthdayWish = action.payload?.birthdayWish;
      state.labOrderArrivalUpdate = action.payload?.labOrderArrivalUpdate;
      state.financeAbstract = action.payload?.financeAbstract;
      state.consent_form = action.payload?.consent_form;
    },
    cleanupUserSettings: (state) => {
      state.loggedIn = false;
      localStorage.removeItem("token");
      state.token = null;
      state.userId = null;
      state.displayName = null;
      state.companyId = null;
      state.role = null;
      state.email = null;
      state.phone = null;
      state.subscriptionInfo = "";
      state.treatment_qty = false;
    },
    openProfileModal: (state) => {
      state.isOpen = true;
    },
    closeProfileModal: (state) => {
      state.isOpen = false;
    },
    updateTreatmentQtyState: (state, action) => {
      state.treatment_qty = action.payload;
    },
  },
});

export const {
  userSettings,
  cleanupUserSettings,
  openProfileModal,
  closeProfileModal,
  updateTreatmentQtyState,
} = authSlice.actions;

export default authSlice.reducer;
