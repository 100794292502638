import { api } from "../../config/axios/interceptor";

export async function getAllTreatmentPatients(date, searchKey) {
  try {
    const response = await api.get(
      `patient/treatments?page=1&pageSize=9999&date=${
        date || ""
      }&search=${searchKey}`
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getUpcomingTreatments(id) {
  try {
    const response = await api.get(`/appointment/view/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}
export async function getUpcomingTreatmentsPrint(id) {
  try {
    const response = await api.get(`/appointment/view3/${id}`, {
      headers: {
        Authorization: "PQwc4WyWXSbMaZe", // Example header
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}
export async function getPrescriptions(id) {
  try {
    const response = await api.get(`/prescription/view/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}
export async function getPrescriptionsPrint(id) {
  try {
    const response = await api.get(`/prescription/view2/${id}`, {
      headers: {
        Authorization: "PQwc4WyWXSbMaZe", // Example header
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}
export async function getLabOrders(id) {
  try {
    const response = await api.get(`/lab/view/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}
export async function getLabOrdersPrint(id) {
  try {
    const response = await api.get(`/lab/view2/${id}`, {
      headers: {
        Authorization: "PQwc4WyWXSbMaZe", // Example header
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}
export async function getLabOrdersPrintSingle(id) {
  try {
    const response = await api.get(`/lab/view3/${id}`, {
      headers: {
        Authorization: "PQwc4WyWXSbMaZe", // Example header
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}
export async function getInvoices(id) {
  try {
    const response = await api.get(`/invoice/view/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}
export async function getInvoicesPrint(id) {
  try {
    const response = await api.get(`/invoice/view2/${id}`, {
      headers: {
        Authorization: "PQwc4WyWXSbMaZe", // Example header
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getSingleTreatment(id) {
  try {
    const response = await api.get(`treatment/view/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}
export async function getSingleTreatmentPrint(id) {
  try {
    const response = await api.get(`treatment/view2/${id}`, {
      headers: {
        Authorization: "PQwc4WyWXSbMaZe", // Example header
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}
// export async function getTreatmentSummaries(id) {
//   try {
//     const response = await api.get(`summary?patientId=${id}`);
//     return response;
//   } catch (error) {
//     throw error;
//   }
// }

export async function addTreatmentSummary(payload) {
  try {
    const response = await api.post(`summary`, payload);
    return response;
  } catch (error) {
    throw error;
  }
}
export async function updateTreatmentSummary(payload, id) {
  try {
    const response = await api.put(`summary/` + id, payload);
    return response;
  } catch (error) {
    throw error;
  }
}
export async function deleteTreatmentSummary(id) {
  try {
    const response = await api.delete(`summary/` + id);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function addTreatmentData(payload) {
  try {
    const response = await api.post(`treatment`, payload);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function editTreatmentData(payload, id) {
  try {
    const response = await api.put(`treatment/` + id, payload);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function updateTreatmentImages(images) {
  var formData = new FormData();
  images?.forEach((image, index) => {
    // Append each image with the same key ("file")
    formData.append("file", image);
  });

  try {
    const response = await api.put(`treatment/images`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export async function uploadConsentImages(images, id) {
  var formData = new FormData();
  images?.forEach((image, index) => {
    // Append each image with the same key ("file")
    formData.append("file", image);
  });

  try {
    const response = await api.put(
      `patient/consent_images?patient_id=${id}`,
      formData
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function removeTreatmentImages(id, payload) {
  try {
    const response = await api.post(
      `patient/deleteTreatmentpic/` + id,
      payload
    );
    return response;
  } catch (error) {
    throw error;
  }
}
export async function removeConsentImages(id, payload) {
  try {
    const response = await api.post(
      `patient/deleteConsentImage/?patient_id=${id}`,
      payload
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getClinicalData(id) {
  try {
    const response = await api.get(`patient/${id}?config=true`);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getEditClinicalData(id, treatmentId) {
  try {
    const response = await api.get(
      `patient/${id}?config=true&treatmentId=${treatmentId}`
    );
    return response;
  } catch (error) {
    throw error;
  }
}
export async function getEditClinicalDataPrint(id, treatmentId) {
  try {
    const response = await api.get(
      `patient/view2/${id}?config=true&treatmentId=${treatmentId}`
    );
    return response;
  } catch (error) {
    throw error;
  }
}
export async function updateClinicalData(id, payload) {
  try {
    const response = await api.put(
      `configuration/patientConfig?patientId=${id}&type=Patient-PreScreening`,
      payload
    );
    return response;
  } catch (error) {
    throw error;
  }
}
export async function updateClinicalDataEdit(id, patientId, payload) {
  try {
    const response = await api.put(
      `configuration/patientTreatmentConfig?treatmentId=${id}&type=Treatment-PreScreening&patientPrescreening=true&patientId=${patientId}`,
      payload
    );
    return response;
  } catch (error) {
    throw error;
  }
}
export async function getTreatmentFiles(file) {
  try {
    const response = await api.get(`treatment/images?file=${file}`, {
      responseType: "blob",
    });
    const blob = response?.data;
    const imageUrl = URL.createObjectURL(blob);
    return imageUrl;
  } catch (error) {
    throw error;
  }
}
export async function getConsentFiles(file) {
  try {
    const response = await api.get(`patient/consent_images?file=${file}`, {
      responseType: "blob",
    });
    const blob = response?.data;
    const imageUrl = URL.createObjectURL(blob);
    return imageUrl;
  } catch (error) {
    throw error;
  }
}
export async function getTreatmentFilesPrint(file) {
  try {
    const response = await api.get(`treatment/images/view2?file=${file}`, {
      responseType: "blob",
    });
    const blob = response?.data;
    const imageUrl = URL.createObjectURL(blob);
    return imageUrl;
  } catch (error) {
    throw error;
  }
}

// export async function getTreatmentFiles(file) {
//   try {

//     let response;
//     let fileExtension = getFileExtension(file.name);

//     if (fileExtension === 'pdf' || fileExtension === 'doc') {
//       // Handle PDF or DOC files
//       response = await api.get(`treatment/documents?file=${file}`, {
//         responseType: "blob",
//       });
//     } else {
//       // Handle image files
//       response = await api.get(`treatment/images?file=${file}`, {
//         responseType: "blob",
//       });
//     }

//     const blob = response?.data;
//     const fileUrl = URL.createObjectURL(blob);
//     return fileUrl;
//   } catch (error) {
//     throw error;
//   }
// }

// // Function to get file extension
// function getFileExtension(filename) {
//   return filename.split('.').pop().toLowerCase();
// }
