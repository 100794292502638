import React, { useEffect, useState } from "react";
import { api } from "../../../config/axios/interceptor";
import { getPopupImageFiles } from "../../../api/auth/user";
import ModalWrapper from "../../common/modal/ModalWrapper";
import Button from "../../common/buttons/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules"; // Corrected import
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const PopupImages = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [imageData, setImageData] = useState([]);
  const [error, setError] = useState(null);
  const [isOpen, setOpen] = useState(false);

  const fetchUserImages = async () => {
    try {
      setIsLoading(true);
      setError(null);

      // Fetch initial user image names
      const response = await api.get("/users/popup");
      setImageData(response.data.data);
      const images = response.data.data;

      if (!images.length > 0) {
        return;
      }

      // Fetch actual image URLs for each image name
      const promises = images?.map((image) => getPopupImageFiles(image.image));
      const urls = await Promise.all(promises);

      // Map URLs with their respective names
      const imageUrls = urls.map((url, index) => ({
        url,
        name: images[index],
      }));
      setImageUrls(imageUrls);
      setOpen(true);
    } catch (err) {
      console.error("Error fetching user popup images:", err);
      setError("Failed to load images. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = async () => {
    try {
      setOpen(false);
      // Fetch initial user image names
      const response = await api.put("/users/popup", {
        popup_status: true,
      });
    } catch (err) {
    } finally {
    }
  };

  useEffect(() => {
    fetchUserImages();
  }, []);

  return (
    <div>
      <ModalWrapper open={isOpen} handleClose={handleCloseModal} title={""}>
        <div className="w-[80vw] h-[80vh] flex flex-col justify-center items-center">
          {/* Carousel Container */}
          <div className="w-full h-auto">
            <Swiper
              modules={[Navigation, Pagination]}
              navigation
              spaceBetween={30}
              slidesPerView={1}
              autoHeight={true} // Enable dynamic height adjustment
            >
              {imageUrls?.map((img, index) => (
                <SwiperSlide key={index}>
                  <div>
                    <img
                      src={img?.url}
                      alt={`Image ${index}`}
                      className="w-full max-h-[400px] object-contain rounded-lg"
                    />
                    {imageData?.[index]?.description && (
                      <p className="pt-5 text-center text-gray-700">
                        {imageData[index].description}
                      </p>
                    )}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          {/* Close Button */}
          <div className="w-[150px]  mt-10">
            <Button
              onClick={handleCloseModal}
              type="primary"
              className={"!h-[45px]"}
            >
              Close
            </Button>
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

export default PopupImages;
