import React, { useState, useRef, useEffect } from "react";

const OTPInput = ({ onOtpChange, className }) => {
  const [otp, setOtp] = useState(Array(4).fill(""));
  const inputRefs = useRef(Array(6).fill(null));

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (!/^[0-9]$/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (index < 5 && value) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (otp[index]) {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
      } else if (index > 0) {
        inputRefs.current[index - 1]?.focus();
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
      }
    }
  };

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData("text");
    if (!/^\d{6}$/.test(pasteData)) return;

    const newOtp = pasteData.split("");
    setOtp(newOtp);

    inputRefs.current[5]?.focus();
  };

  useEffect(() => {
    onOtpChange(otp);
  }, [otp]);

  return (
    <div onPaste={handlePaste} style={{ display: "flex", gap: "20px" }}>
      {otp.map((_, index) => (
        <input
          key={index}
          ref={(el) => (inputRefs.current[index] = el)}
          value={otp[index]}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          maxLength={1}
          style={{ textAlign: "center", fontSize: "18px" }}
          className={`border rounded-[8px] w-[60px] h-[60px] outline-primary ${className}`}
        />
      ))}
    </div>
  );
};

export default OTPInput;
